
//import router from '@/router'
import axios from 'axios';
import Swal from "sweetalert2";
//import { useCookies } from "vue3-cookies";
//const { cookies } = useCookies();

import serverConfig from '@/helpers/config.js'

export const state = {
    currentCart: [],
    quotations:[],
    orx:false,
}
export const mutations = {

    SET_BRANDS(state, value){
        state.brands = value
    },
    SET_CURRENT_CART(state, value) {
        state.currentCart = value
    },
    SET_ORX(state, value) {
        state.orx = value
    },
    SET_DETAIL_CART(state, value) {
        state.detailCart = value
    },
    
}

export const getters = {
  
    getBrands(state) {
        return state.brands
    },
    getCart(state) {
        return state.detailCart
    },
    getOrx(state) {
        return state.orx
    }
}

export const actions = {

    async getCartByOrd({commit}, orx ) {
        axios.get(`${serverConfig.EP}carts/orx/${orx}`, 
        { 
          headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
        }
       ).then((response)=>{
            if (response.data){
                commit('SET_DETAIL_CART', response.data)
                
            }
       })
    },
    
    async deleteItem({dispatch}, id, orx) {
       console.log("delete")
       console.log(id)
       
        axios.delete(`${serverConfig.EP}carts/item/${id}`, 
        { 
          headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
        }
       ).then((response)=>{
            if (response.data){
                alert('Articolo rimosso')
                dispatch('getCartByOrd', orx);
            }
       }).catch( (e) =>{
        console.log(e)
        alert('Errore di rimozione')
       })
    },
    async addToCart({commit,dispatch}, data ) {
       

        axios.get(`${serverConfig.EP}carts/calc-price`, 
        { 
          params:data, 
          headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
        }
       ).then((response)=>{
            if (response.data){
                console.log(response.data)
                if (response.data.status){
                    if (response.data.status=='Ok'){
                        
                        commit('SET_CURRENT_CART', response.data)
                        
                        commit('SET_ORX', response.data.orx)
                        localStorage.setItem('orx',response.data.orx)
                        

                        Swal.fire({
                            title:"Prodotto selezionato",
                            text:"Il prodotto selezionato è stato aggiunto con successo alla tua lista!",
                            icon:"success",
                            showCancelButton:false,
                            showConfirmButton:false,
                            timer:1000,
                        })

                        dispatch('getCartByOrd',response.data.orx);
                    } else {
                        localStorage.removeItem('orx')
                        if (response.data.status=='Error-uuid'){
                            Swal.fire({
                                title:"Errore di identificazione",
                                text:"Mi spiace, ma il codice identificativo dell'ordine risulta sbagliato. Se il problema persiste. Effettua il logout dal sistema e riprova.",
                                icon:"error",
                                showCancelButton:false,
                                showConfirmButton:false,
                                timer:5000,
                            })
                        }
    
                    }
                } else {
                    Swal.fire({
                        title:"Selezione prodotto fallita",
                        text:"Mi spiace, non è possibile aggiungere il prodotto selezionato.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:5000,
                    })
                }

            }
       })
    },
    async addItemToOrder({ commit, dispatch}, data ) {
        axios.post(`${serverConfig.EP}carts/add-item-to-order`, 
            data,
            { 
                headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
            }
       ).then((response)=>{
            if (response.data){
                console.log(response.data)
                if (response.data.status){
                    if (response.data.status=='Ok'){
                        
                        commit('SET_CURRENT_CART', response.data)
                        
                        commit('SET_ORX', response.data.orx)
                        localStorage.setItem('orxpro',response.data.orx)
                        

                        Swal.fire({
                            title:"Prodotto selezionato",
                            text:"Il prodotto selezionato è stato aggiunto con successo alla tua lista!",
                            icon:"success",
                            showCancelButton:false,
                            showConfirmButton:false,
                            timer:1000,
                        })

                        dispatch('getCartByOrd',response.data.orx);
                    } else {
                        localStorage.removeItem('orx')
                        if (response.data.status=='Error-uuid'){
                            Swal.fire({
                                title:"Errore di identificazione",
                                text:"Mi spiace, ma il codice identificativo dell'ordine risulta sbagliato. Se il problema persiste. Effettua il logout dal sistema e riprova.",
                                icon:"error",
                                showCancelButton:false,
                                showConfirmButton:false,
                                timer:5000,
                            })
                        }
    
                    }
                } else {
                    Swal.fire({
                        title:"Selezione prodotto fallita",
                        text:"Mi spiace, non è possibile aggiungere il prodotto selezionato.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:5000,
                    })
                }

            }
       })
    },
    async updateCart({commit,dispatch}, data ) {
       

        axios.get(`${serverConfig.EP}carts/update-cart`, 
        { 
          params:data, 
          headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
        }
       ).then((response)=>{
            if (response.data){
                console.log(response.data)
                if (response.data.status){
                    if (response.data.status=='Ok'){
                        
                        commit('SET_CURRENT_CART', response.data)
                        
                        commit('SET_ORX', response.data.orx)
                        localStorage.setItem('orx',response.data.orx)
                        

                        Swal.fire({
                            title:"Prodotto selezionato",
                            text:"Il prodotto selezionato è stato aggiunto con successo alla tua lista!",
                            icon:"success",
                            showCancelButton:false,
                            showConfirmButton:false,
                            timer:1000,
                        })

                        dispatch('getCartByOrd',response.data.orx);
                    } else {
                        localStorage.removeItem('orx')
                        if (response.data.status=='Error-uuid'){
                            Swal.fire({
                                title:"Errore di identificazione",
                                text:"Mi spiace, ma il codice identificativo dell'ordine risulta sbagliato. Se il problema persiste. Effettua il logout dal sistema e riprova.",
                                icon:"error",
                                showCancelButton:false,
                                showConfirmButton:false,
                                timer:5000,
                            })
                        }
    
                    }
                } else {
                    Swal.fire({
                        title:"Selezione prodotto fallita",
                        text:"Mi spiace, non è possibile aggiungere il prodotto selezionato.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:5000,
                    })
                }

            }
       })
    },
    async resetCurrentCart({commit}) {
        commit('SET_CURRENT_CART', null)
        commit('SET_DETAIL_CART', null)
        commit('SET_ORX', null)
    },
    async getBrands({commit}) {

        if (localStorage.getItem('tk')){
            try {
                axios.get(`${serverConfig.EP}brands/list`,  
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
                ).then((response)=>{


                    commit('SET_BRANDS', response.data)
                   
                })
                .catch(error=>{
                    if (error =='Error: Request failed with status code 500'){
                        Swal.fire({
                          title:"Errore 500",
                          text:"Spiacente, il server ha riscontrato un errore. Non è stato possibile importare le marche",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                      }
                })

            } catch(e) {
                
                Swal.fire({
                    title:"Errore 500",
                    text:"Spiacente, il server ha riscontrato un errore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                })

            }
        }

    },
    async getRetailPrice({commit}, id) {


        if (localStorage.getItem('tk')){
            try {
                axios.get(`${serverConfig.EP}products/retail-price/${id}`,  
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
                ).then((response)=>{
                    
                    commit('SET_RETAIL_PRICE', response.data)
                })
                .catch(error=>{
                    if (error =='Error: Request failed with status code 500'){
                        Swal.fire({
                          title:"Errore 500",
                          text:"Spiacente, il server ha riscontrato un errore",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                      }
                })

            } catch(e) {
                
                Swal.fire({
                    title:"Errore 500",
                    text:"Spiacente, il server ha riscontrato un errore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                })

            }
        }

    },

}

// ===
// Private helpers
// ===

