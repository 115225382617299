import axios from 'axios';
import Swal from "sweetalert2";
import serverConfig from '@/helpers/config.js'


export const mutations = {
    SET_CURRENT_CALENDAR(state, newValue) {
        state.calendar = newValue
       
    },
    SET_LASTEVENT_UPDATED(state, newValue) {
      state.lasteventupdated = newValue
     
  },
   
}

export const getters = {

    getCalendar(state) {
        return state.calendar
    },


}

export const actions = {


    async getCalendarFilter({ commit}, {body}) {
      let tk = localStorage.getItem('tk')
      console.log(body)
      axios.get(`${serverConfig.EP}auth/users`,  
          {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
          console.log(response)
          console.log(body)
          commit('SET_CURRENT_CALENDAR', response.data)
          
          
        })
    },
    async createNewEvent({ commit}, body) {

        if (localStorage.getItem('tk'))
        {

            try {
                axios.post(`${serverConfig.EP}events/new-event`,  
                  body,
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
               ).then((response)=>{
               
                  commit('SET_CURRENT_CALENDAR',  response.data.userData)
                  Swal.fire({
                    title:"Nuovo evento creato",
                    text:"Un nuovo evento è stato creato con successo",
                    icon:"success",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                  })
                
               })
        
              }  catch (error) {
                return
              }

        }
        
  
  
    },
    async updateEvent({commit}, { id, body } = {}) {
      console.log("STATE")
      alert(JSON.stringify(body))
      if (localStorage.getItem('tk'))
      {

          try {
              axios.put(`${serverConfig.EP}events/${id}`,  
                body,
              {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
             ).then((response)=>{
              if(response) {
                Swal.fire({
                  title:"Evento modificato",
                  text:"Evento è stato creato con successo",
                  icon:"success",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000,
                })
                
                commit('SET_LASTEVENT_UPDATED',  response.data)
              }
             })
      
            }  catch (error) {
              return
            }

      }
      


  },    
    async dragAndDropNewEvent({ commit}, body) {

      if (localStorage.getItem('tk'))
      {

          try {
              axios.post(`${serverConfig.EP}events/new-event`,  
                body,
              {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
             ).then((response)=>{
                commit('SET_CURRENT_CALENDAR',  response.data.userData)
             })
      
            }  catch (error) {
            
              return
            }

      }
      


  },
  
}
