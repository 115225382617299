import store from "@/state/store";
import axios from 'axios';
import Swal from "sweetalert2";
import serverConfig from '@/helpers/config.js'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch((errore)=>{
            console.log(errore)
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }

      },
    },
  },
  {
    path: "/register/:token",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo,routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
         localStorage.removeItem('VUEX-PERSISTEDSTATE')
         localStorage.removeItem('tk')
         sessionStorage.removeItem('auth.currentUser')
        }

        let param = routeTo.path.split('/register/')[1]
        axios.get(`${serverConfig.EP}users/check-auth-token/${param.toString()}`)
        .then((response)=>{
         
          if (response.data.check==true){
            Swal.fire({
              titleText: 'Inserisci il codice segreto presente nella email di invito',
              input: 'number',
              inputPlaceholder:'Inserisci codice',
              showCancelButton: true,
              confirmButtonText: 'Conferma',
              cancelButtonText: 'Annulla',
              showLoaderOnConfirm: true,
              preConfirm: (code) => {
                
                if (code.length == 6){
                  return axios.get(`${serverConfig.EP}users/check-auth-code/${param}/${code}`)
                  .then(response => {
                    
                    if (response.data.check == false) {
                      throw new Error("Codice non corretto.")
                    }
                    console.log(response)
                    next()
                    return
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  })
                } else {
                  Swal.fire({
                    title:"Codice non valido",
                    text:"Inserisci un codice di 6 cifre valido, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                  }).then(()=>{
                   window.location.reload()
                   })
                }

              },
              allowOutsideClick: false
            })
            /*
            .then((result) => {
              if (result.isConfirmed) {
                next()
              }
            })
            */
          }else{
            Swal.fire({
              title:"Token non valido",
              text:"Spiacente, ma il token di autenticazione non risulta valido.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }).then(() => {
              next('/login')
              return
            })
          }
          
        })
        .catch(error=>{
          if (error =='Error: Request failed with status code 500'){
            Swal.fire({
              title:"Errore 500",
              text:"Spiacente, il server ha riscontrato un errore",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            })
          }
        })
        
      }

    },
  },
  
  {
    path: "/regenerate-password/:token",
    name: "regenerate-password",
    component: () => import("../views/account/regeneratePassword.vue"),
    meta: {
      title: "Rigenera password",
      beforeResolve(routeTo,routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
         localStorage.removeItem('VUEX-PERSISTEDSTATE')
         localStorage.removeItem('tk')
         sessionStorage.removeItem('auth.currentUser')
        }

        let param = routeTo.path.split('/regenerate-password/')[1]
        axios.get(`${serverConfig.EP}users/check-auth-token/${param.toString()}`)
        .then((response)=>{
         
          if (response.data.check==true){
            Swal.fire({
              titleText: 'Inserisci il codice segreto presente nella email di invito',
              input: 'number',
              inputPlaceholder:'Inserisci codice',
              showCancelButton: true,
              confirmButtonText: 'Conferma',
              cancelButtonText: 'Annulla',
              showLoaderOnConfirm: true,
              preConfirm: (code) => {
                
                if (code.length == 6){
                  return axios.get(`${serverConfig.EP}users/check-auth-code/${param}/${code}`)
                  .then(response => {
                    
                    if (response.data.check == false) {
                      throw new Error("Codice non corretto.")
                    }
                    
                    next()
                    return
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  })
                } else {
                  Swal.fire({
                    title:"Codice non valido",
                    text:"Inserisci un codice di 6 cifre valido, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3000,
                  }).then(()=>{
                   window.location.reload()
                   })
                }

              },
              allowOutsideClick: false
            })

          }else{
            Swal.fire({
              title:"Token non valido",
              text:"Spiacente, ma il token di autenticazione non risulta valido.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }).then(() => {
              next()
              return
            })
          }
          
        })
        .catch(error=>{
          if (error =='Error: Request failed with status code 500'){
            Swal.fire({
              title:"Errore 500",
              text:"Spiacente, il server ha riscontrato un errore",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            })
          }
        })
        
      }

    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Password dimenticata",
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/forgot-password')
          })
        } else {
          next()
        }

      },
    },
  },
  {
    path: "/",
    name: "default",
    redirect: '/my-dashboard',
    meta: {
      title: "Dashboard",
      authRequired: true,
  
      beforeResolve(routeTo, routeFrom, next) {
       
        
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch((errore)=>{
            console.log(errore)
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }


      },
    },

   component: () => import("../views/dashboard/my-impiantino/index"),
   
  },
/* Personalizzazione Impiantino */
// Area Personale (My Impiantino)

{
  path: "/my-dashboard",
  name: "myDashboard",
  meta: {
    title: "La Mia Bacheca",
    authRequired: true,
   // beforeResolve  () {
    beforeResolve(routeTo, routeFrom, next) {
     
      
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }


    },
  },
 // component: () => import("../views/dashboard/ecommerce/index.vue"),
 component: () => import("../views/dashboard/my-impiantino/index"),
},
{
  path: "/my-dashboard/notifications",
  name: "my-dashboard-notifications",
  meta: { 
    title: "Visualizzazione elenco notifiche", 
    authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },      
  },
  component: () => import("../views/dashboard/my-impiantino/notifications"),
},  
{
  path: "/my-calendar",
  name: "myCalendar",
  meta: {
    title: "Il mio calendario",
    authRequired: true,
   // beforeResolve  () {
    beforeResolve(routeTo, routeFrom, next) {
     
      
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }


    },
  },
 // component: () => import("../views/dashboard/ecommerce/index.vue"),
 component: () => import("../views/calendar/index"),
},
{
  path: "/my-projects/list-view",
  name: "my-projects-list-view",
  meta: { 
    title: "Visualizzazione elenco commesse", 
    authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },      
  },
  component: () => import("../views/apps/projects/list-view"),
},  

{
  path: "/my-projects/detail/:id",
  name: "my-projects-detail",
  meta: { title: "Dettaglio Commessa", authRequired: true,      
  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
       if (errore){
        localStorage.removeItem('tk')
        next('/login')
       }
      })
    } else {
      next()
    }
   
  },   },
  component: () => import("../views/apps/projects/detail"),
},
{
  path: "/my-projects/detail/gantt/:id",
  name: "my-projects-gantt",
  meta: { title: "Gantt Progettazione ", authRequired: true,      
  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
       if (errore){
        localStorage.removeItem('tk')
        next('/login')
       }
      })
    } else {
      next()
    }
   
  },   },
  component: () => import("../views/apps/projects/gantt"),
},
{
  path: "/my-projects/detail/dms/:id",
  name: "my-project-dms",
  meta: { title: "Gestione documenti progetto", authRequired: true,      
  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
       if (errore){
        localStorage.removeItem('tk')
        next('/login')
       }
      })
    } else {
      next()
    }
   
  },   },
  component: () => import("../views/apps/projects/dms"),
},
// / Area Commerciale

// Area Commerciale
{
  path: "/sales-area/list-view",
  name: "sales-area-list-view",
  meta: { 
    title: "Visualizzazione elenco commesse", 
    authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },      
  },
  component: () => import("../views/apps/projects/list-view"),
},  
{
  path: "/sales-area/detail/:id",
  name: "sales-area-detail",
  meta: { title: "Dettaglio Commessa", authRequired: true,      
  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
       if (errore){
        localStorage.removeItem('tk')
        next('/login')
       }
      })
    } else {
      next()
    }
   
  },   },
  component: () => import("../views/apps/projects/detail"),
},

// / Area Commerciale
// Gestione progetti
  {
    path: "/projects/list-view",
    name: "projects-list-view",
    meta: { 
      title: "Visualizzazione elenco progetti", 
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch((errore)=>{
           if (errore){
            localStorage.removeItem('tk')
            next('/login')
           }
          })
        } else {
          next()
        }
       
      },      
    },
    component: () => import("../views/apps/projects/list-view"),
  },  
  {
    path: "/projects/detail/:id",
    name: "project-detail",
    meta: { title: "Dettaglio progetto", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/detail"),
  },
  {
    path: "/projects/detail/dms/:id",
    name: "project-dms",
    meta: { title: "Gestione documenti progetto", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/dms"),
  },
  {
    path: "/projects/detail/inspection/:id",
    name: "project-inspection",
    meta: { title: "Gestione sopralluogo progetto", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/inspection"),
  },
  {
    path: "/projects/detail/accountancy/:id",
    name: "project-accountancy",
    meta: { title: "Gestione amministrativa progetto", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/accountancy"),
  },
  {
    path: "/projects/detail/plan/:id",
    name: "project-plan",
    meta: { title: "Progettazione", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/plan"),
  },
  {
    path: "/projects/detail/supplies-products/:id",
    name: "project-supply-products",
    meta: { title: "Forniture e Prodotti ", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/suppliesProducts"),
  },
  {
    path: "/projects/detail/gantt/:id",
    name: "project-gantt",
    meta: { title: "Gantt Progettazione ", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/gantt"),
  },
  // / Gestione progetti
  {
    path: "/inspections/list-view",
    name: "inspections-list",
    meta: { title: "Sopralluoghi", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/inspectionsList"),
  },
  // / Gestione progetti
  {
    path: "/plan/list-view",
    name: "plan-list",
    meta: { title: "Pianificazione Lavori", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/planList"),
  },
  
  // / Gestione progetti
  {
    path: "/accounting/list",
    name: "accounting-list",
    meta: { title: "Pianificazione Lavori", authRequired: true,      
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
         if (errore){
          localStorage.removeItem('tk')
          next('/login')
         }
        })
      } else {
        next()
      }
     
    },   },
    component: () => import("../views/apps/projects/accountingList"),
  },
// Gestione cantieri
{
  path: "/worksites/list-view",
  name: "worksites-list-view",
  meta: { title: "Gestione lavorazioni", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/worksites/list-view"),
},
{
  path: "/wip/timeline/:id",
  name: "wip-timeline",
  meta: { title: "Dettaglio WIP Timeline progetto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/wip"),
},
{
  path: "/worksite/detail/:id",
  name: "worksite-detail",
  meta: { title: "Dettaglio gestione cantiere", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/detail"),
},
{
  path: "/worksite/detail/:id/task/:taskid",
  name: "worksite-detail-task",
  meta: { title: "Dettaglio attività progetto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/detailTask"),
},


// Gestione ASSISTENZA
{
  path: "/support/list-view",
  name: "support-list-view",
  meta: { title: "Gestione assistenza", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/support/list-view"),
},
{
  path: "/support/detail/:id",
  name: "support-detail",
  meta: { title: "Dettaglio progetto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/detail"),
},
{
  path: "/support/detail/task/:id/:taskid",
  name: "support-detail-task",
  meta: { title: "Dettaglio attività progetto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/detailTask"),
},
// Gestione ATTIVITA'
{
  path: "/tasks/list-view",
  name: "tasks-list",
  meta: { title: "Elenco Attività richieste", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/tasks/list-view"),
},
{
  path: "/tasks/detail/:id",
  name: "tasks-detail",
  meta: { title: "Dettaglio Attività", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/tasks/detailTask"),
},
{
  path: "/tasks-reports/list-view",
  name: "tasks-reports-list-view",
  meta: { title: "Gestione interventi", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/techreports/list-view"),
},
{
  path: "/tasks-reports/detail/:id/:worksiteid/:taskid",
  name: "tasks-reports-detail",
  meta: { title: "Dettaglio Rapporto d'intervento", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/techreports/detail"),
},
// Gestione flotta 
{
  path: "/fleet/list-view",
  name: "fleet-list-view",
  meta: { title: "Gestione Flotta", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/fleet/list-view"),
},
{
  path: "/fleet/detail/:id",
  name: "fleet-detail",
  meta: { title: "Dettaglio mezzo", authRequired: true,      
  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
       if (errore){
        localStorage.removeItem('tk')
        next('/login')
       }
      })
    } else {
      next()
    }
   
  },   },
  component: () => import("../views/apps/fleet/detail"),
},

//intervention-reports/
{
  path: "/intervention-reports/list-view",
  name: "intervention-reports-list-view",
  meta: { title: "Gestione interventi", authRequired: true,  beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      
 },
  component: () => import("../views/apps/techreports/list-view"),
},
{
  path: "/intervention-reports/detail/:id/:worksiteid/:taskid",
  name: "intervention-reports-detail",
  meta: { title: "Dettaglio Rapporto d'intervento", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/techreports/detail"),
},
// / intervention-reports
{
  path: "/fgas/detail/:id",
  name: "fgas-detail",
  meta: { title: "Dettaglio attività FGAS", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
    if(localStorage.getItem('tk')){
      sendGetRequest().then((response)=>{
        if(response){
          next()
        } else {
          localStorage.removeItem('tk')
        }
      }).catch((errore)=>{
        
        if (errore){
        localStorage.removeItem('tk')
        next('/login')
        }

      })
    } else {
      next()
    }
   
  },      

  },
  component: () => import("../views/apps/worksites/detailFgas"),
},
{
  path: "/worksite/overview",
  name: "worksite Overview",
  meta: { title: "Projects-overview", authRequired: true },
  component: () => import("../views/apps/worksites/overview"),
},
{
  path: "/worksite/create",
  name: "worksite-create",
  meta: { title: "worksite-create", authRequired: true },
  component: () => import("../views/apps/worksites/create"),
},


/* / Personalizzazione EnviLab */
{
  path: "/users/list",
  name: "users-list",
  meta: { 
    title: "Lista utenti", 
    authRequired: true, 
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch(()=>{
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    }, 
  },
  component: () => import("../views/account/users"),
},
{
  path: "/user/:id",
  name: "user-detail",
  meta: { 
    title: "Dettaglio utente", 
    authRequired: true, 
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          console.log("errore")
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    }, 
  },
  component: () => import("../views/account/detail"),
},
  {
    path: "/customers/list",
    name: "customers-list",
    meta: { 
      title: "Lista clienti",
      authRequired: true, 
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }
  
      },    
    },
    component: () => import("../views/apps/crm/customers"),
  },
  {
    path: "/customers/detail/:id",
    name: "customer-detail",
    meta: { 
      title: "Dettaglio cliente",
      authRequired: true, 
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }
  
      },    
    },
    component: () => import("../views/apps/crm/detail"),
  },
  {
    path: "/dashboard/projects",
    name: "dashboard-projects",
    meta: {
      title: "Projects", authRequired: true,
    },
    component: () => import("../views/dashboard/projects/index"),
  },
  {
    path: "/dashboard/crm",
    name: "dashboard-crm",
    meta: {
      title: "CRM", authRequired: true,
    },
    component: () => import("../views/dashboard/crm/index"),
  },
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    meta: {
      title: "Analytics", authRequired: true,
    },
    component: () => import("../views/dashboard/analytics/index"),
  },
  
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear()
        if (localStorage.getItem("tk")){
          localStorage.removeItem("tk")
        }   
        if (cookies.get("tk")){
          cookies.remove("tk")
          
        }   
        
        next()
        
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { 
      title: "Calendar", 
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch((errore)=>{
            console.log("errore")
            console.log(errore)
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }
  
      },        
    },
    component: () => import("../views/calendar/index"),
  },
  {
    path: "/worksites/calendar",
    name: "worksites calendar",
    meta: { 
      title: "Worksites Calendar", 
      authRequired: true, 
      beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
            }
          }).catch((errore)=>{
            console.log("errore")
            console.log(errore)
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }
  
      },         
    },
    component: () => import("../views/calendar/index"),
  },
  {
    path: "/chat",
    name: "chat",
    meta: { title: "Chat", authRequired: true },
    component: () => import("../views/apps/chat"),
  },
  {
    path: "/apps-file-manager",
    name: "file manager",
    meta: { title: "File Manager", authRequired: true },
    component: () => import("../views/apps/filemanager"),
  },
  {
    path: "/apps-todo",
    name: "To Do List",
    meta: { title: "To Do List", authRequired: true },
    component: () => import("../views/apps/todo"),
  },
  {
    path: "/mailbox",
    name: "mailbox",
    meta: { title: "Mailbox", authRequired: true },
    component: () => import("../views/apps/email/mailbox"),
  },
  {
    path: "/email/email-basic",
    name: "email-basic",
    meta: { title: "Basic Action", authRequired: true },
    component: () => import("../views/apps/email/email-basic"),
  },
  {
    path: "/email/email-ecommerce",
    name: "email-ecommerce",
    meta: { title: "Ecommerce Action", authRequired: true },
    component: () => import("../views/apps/email/email-ecommerce"),
  },

  {
    path: "/apps/tasks-details",
    name: "tasks-details",
    meta: { title: "Tasks Details", authRequired: true },
    component: () => import("../views/apps/tasks/details"),
  },
  {
    path: "/apps/tasks-kanban",
    name: "tasks-kanban",
    meta: { title: "Tasks Kanban", authRequired: true },
    component: () => import("../views/apps/tasks/kanban"),
  },
  {
    path: "/apps/tasks-list-view",
    name: "tasks-list-view",
    meta: { title: "Taks List", authRequired: true },
    component: () => import("../views/apps/tasks/list-view"),
  },
  {
    path: "/apps/tickets-details",
    name: "tickets-details",
    meta: { title: "Ticket Details", authRequired: true },
    component: () => import("../views/tickets/details"),
  },
  {
    path: "/apps/tickets-list",
    name: "tickets-list",
    meta: { title: "Tickets List", authRequired: true },
    component: () => import("../views/tickets/list"),
  },
  {
    path: "/apps/crm-contacts",
    name: "crm-contacts",
    meta: { title: "CRM Contacts", authRequired: true },
    component: () => import("../views/apps/crm/contacts"),
  },
  {
    path: "/apps/crm-companies",
    name: "crm-companies",
    meta: { title: "CRM companies", authRequired: true },
    component: () => import("../views/apps/crm/companies"),
  },
  {
    path: "/apps/crm-deals",
    name: "crm-deals",
    meta: { title: "CRM Deals", authRequired: true },
    component: () => import("../views/apps/crm/deals"),
  },
  {
    path: "/apps/crm-leads",
    name: "crm-leads",
    meta: { title: "CRM Leads", authRequired: true },
    component: () => import("../views/apps/crm/leads"),
  },
  {
    path: "/ecommerce/customers",
    name: "customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/apps/ecommerce/customers"),
  },

  { 
    // Impiantino Store
    path: "/ecommerce/products",
    name: "products",
    meta: { title: "Catalogo prodotti", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
              next('/login')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }

      },         
    },
    component: () => import("../views/apps/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-details/:id",
    name: "product-detail",
    meta: { title: "Dettaglio prodotto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
            next('/login')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    },         },
    component: () => import("../views/apps/ecommerce/product-details"),
  },
  // Gestione listini 
  { // in lavorazione
    path: "/ecommerce/price-list",
    name: "price-list",
    meta: { title: "Elenco listini", authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
            next('/login')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }
    },    
    },
    component: () => import("../views/apps/ecommerce/price-list"),
  },
  { // IMPORTA LISTINI
    path: "/ecommerce/price-list/import",
    name: "price-list-import",
    meta: { title: "Importazione listini", authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
            next('/login')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }
    },    
    },
    component: () => import("../views/apps/ecommerce/priceListImport"),
  },
  {
    path: "/ecommerce/price-list-detail/:id",
    name: "price-list-detail",
    meta: { title: "Dettaglio listino", authRequired: true },
    component: () => import("../views/apps/ecommerce/price-list-detail"),
  },
  // / Gestione listini
  // Gestione Canali 
  {
    path: "/ecommerce/sales-route-list",
    name: "sales-route-list",
    meta: { title: "Elenco Canali", authRequired: true },
    component: () => import("../views/apps/ecommerce/salesRoutelist"),
  },
  {
    path: "/ecommerce/sales-route-detail/:id",
    name: "sales-route-detail",
    meta: { title: "Dettaglio canale", authRequired: true },
    component: () => import("../views/apps/ecommerce/salesRouteDetail"),
  },
  // / Gestione Canali
  // Gestione Marche 
  {
    path: "/ecommerce/brand-list",
    name: "brand-list",
    meta: { title: "Elenco Marche", authRequired: true },
    component: () => import("../views/apps/ecommerce/brandList"),
  },
  {
    path: "/ecommerce/brand-detail/:id",
    name: "brand-detail",
    meta: { title: "Dettaglio Marca", authRequired: true },
    component: () => import("../views/apps/ecommerce/salesRouteDetail"),
  },
  // / Gestione Marche
  // Gestione Famiglie 
  {
    path: "/ecommerce/categories-list",
    name: "categories-list",
    meta: { title: "Elenco Categorie", authRequired: true },
    component: () => import("../views/apps/ecommerce/categoriesList"),
  },
  {
    path: "/ecommerce/category-detail/:id",
    name: "category-detail",
    meta: { title: "Dettaglio Categoria", authRequired: true },
    component: () => import("../views/apps/ecommerce/categoryDetail"),
  },
  // / Gestione Famiglie
  {
    path: "/ecommerce/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/apps/ecommerce/orders"),
  },
  {
    path: "/ecommerce/order-details",
    name: "order-details",
    meta: { title: "Order Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/order-details"),
  },
  {
    path: "/ecommerce/order/:orx/:type",
    name: "order-orx-details",
    meta: { title: "Dettaglio ordine", authRequired: true },
    component: () => import("../views/apps/ecommerce/order-admin-details"),
  },

  {
    path: "/ecommerce/edit-product/:id",
    name: "add-product",
    meta: { title: "Modifica prodotto", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-product"),
  },
  {
    path: "/ecommerce/summary-cart",
    name: "summary-cart",
    meta: { title: "Summary Cart", authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    },         
  },
    component: () => import("../views/apps/ecommerce/summary-cart"),
  },
  {
    path: "/quote/details/:id/:type",
    name: "quote-details",
    meta: { title: "Dettagli quotazione",authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    },          },
    component: () => import("../views/apps/ecommerce/quote-details"),
  },

  
  {
    path: "/ecommerce/shopping-cart",
    name: "shopping-cart",
    meta: { title: "Shopping Cart", authRequired: true },
    component: () => import("../views/apps/ecommerce/shopping-cart"),
  },
  {
    path: "/ecommerce/checkout/:orx",
    name: "checkout",
    meta: { title: "Check Out", authRequired: true,      beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
          }
        }).catch((errore)=>{
          alert('errore')
          console.log("errore")
          console.log(errore)
          //localStorage.removeItem('tk')
          //next('/login')
        })
      } else {
        next()
      }

    },          },
    component: () => import("../views/apps/ecommerce/checkout"),
  },
  // Supply Chain - Warehouse
  { 
  
    path: "/warehouse/products",
    name: "warehouse-list",
    meta: { title: "Elenco prodotti in magazzino", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
              next('/login')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }

      },         
    },
    component: () => import("../views/apps/warehouse/products"),
  },
  {
    path: "/warehouse/edit-product/:id",
    name: "edit-product-warehouse",
    meta: { title: "Modifica prodotto", authRequired: true },
    component: () => import("../views/apps/warehouse/add-product"),
  },
  {
    path: "/warehouse/product-details/:id",
    name: "warehouse-product-detail",
    meta: { title: "Dettaglio prodotto", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
      if(localStorage.getItem('tk')){
        sendGetRequest().then((response)=>{
          if(response){
            next()
          } else {
            localStorage.removeItem('tk')
            next('/login')
          }
        }).catch((errore)=>{
          console.log(errore)
          localStorage.removeItem('tk')
          next('/login')
        })
      } else {
        next()
      }

    },         },
    component: () => import("../views/apps/warehouse/product-details"),
  },
  { 
    path: "/warehouse/mov/rfid/in",
    name: "warehouse-mov-rfid-in",
    meta: { title: "Movimentazione prodotti in Entrata", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
              next('/login')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }

      },         
    },
    component: () => import("../views/apps/warehouse/movements"),
  },
  { 
    path: "/warehouse/mov/rfid/out",
    name: "warehouse-mov-rfid-out",
    meta: { title: "Movimentazione prodotti in Uscita", authRequired: true, beforeResolve(routeTo, routeFrom, next) {
        if(localStorage.getItem('tk')){
          sendGetRequest().then((response)=>{
            if(response){
              next()
            } else {
              localStorage.removeItem('tk')
              next('/login')
            }
          }).catch(()=>{
            localStorage.removeItem('tk')
            next('/login')
          })
        } else {
          next()
        }

      },         
    },
    component: () => import("../views/apps/warehouse/movements"),
  },
    // Gestione listini 
    { // in lavorazione
      path: "/warehouse/price-list",
      name: "price-list-warehouse",
      meta: { title: "Elenco listini", authRequired: true },
      component: () => import("../views/apps/warehouse/price-list"),
    },
    {
      path: "/warehouse/price-list-detail/:id",
      name: "price-list-warehouse-detail",
      meta: { title: "Dettaglio listino", authRequired: true },
      component: () => import("../views/apps/warehouse/price-list-detail"),
    },
    // / Gestione listini
   
    {
      path: "/warehouse/brand-list",
      name: "brand-list-warehouse",
      meta: { title: "Elenco Marche", authRequired: true },
      component: () => import("../views/apps/warehouse/brandList"),
    },
    {
      path: "/warehouse/brand-detail/:id",
      name: "brand-detail-warehouse",
      meta: { title: "Dettaglio Marca", authRequired: true },
      component: () => import("../views/apps/warehouse/salesRouteDetail"),
    },
    // / Gestione Marche
    // Gestione Famiglie 
    {
      path: "/warehouse/categories-list",
      name: "categories-list-warehouse",
      meta: { title: "Elenco Categorie", authRequired: true },
      component: () => import("../views/apps/warehouse/categoriesList"),
    },
    {
      path: "/warehouse/category-detail/:id",
      name: "category-detail-warehouse",
      meta: { title: "Dettaglio Categoria", authRequired: true },
      component: () => import("../views/apps/warehouse/categoryDetail"),
    },
    // / Gestione Famiglie
    {
      path: "/warehouse/orders",
      name: "orders-warehouse",
      meta: { title: "Orders", authRequired: true },
      component: () => import("../views/apps/warehouse/orders"),
    },

    {
      path: "/warehouse/order/:orx/:type",
      name: "order-orx-details-warehouse",
      meta: { title: "Dettaglio ordine", authRequired: true },
      component: () => import("../views/apps/warehouse/order-admin-details"),
    },
  // / Supply Chain - Warehouse


  {
    path: "/ecommerce/sellers",
    name: "sellers",
    meta: { title: "Sellers", authRequired: true },
    component: () => import("../views/apps/ecommerce/sellers"),
  },
  {
    path: "/ecommerce/seller-details",
    name: "seller-details",
    meta: { title: "Seller Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/seller-details"),
  },
  {
    path: "/crypto/buy-sell",
    name: "buy/sell",
    meta: { title: "Buy & Sell", authRequired: true },
    component: () => import("../views/apps/crypto/buy-sell"),
  },
  {
    path: "/crypto/kyc",
    name: "kyc",
    meta: { title: "KYC Application", authRequired: true },
    component: () => import("../views/apps/crypto/kyc"),
  },
  {
    path: "/crypto/ico",
    name: "ico",
    meta: { title: "ICO List", authRequired: true },
    component: () => import("../views/apps/crypto/ico"),
  },
  {
    path: "/crypto/orders",
    name: "crypto-orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/apps/crypto/orders"),
  },
  {
    path: "/crypto/wallet",
    name: "wallet",
    meta: { title: "My Wallet", authRequired: true },
    component: () => import("../views/apps/crypto/wallet"),
  },
  {
    path: "/crypto/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: true },
    component: () => import("../views/apps/crypto/transactions"),
  },
  {
    path: "/invoices/detail/:id?",
    name: "invoice-detail",
    meta: { title: "Invoice Details", authRequired: true },
    component: () => import("../views/apps/invoices/details"),
  },
  {
    path: "/invoices/list",
    name: "invoice-list",
    meta: { title: "Invoice List", authRequired: true },
    component: () => import("../views/apps/invoices/list"),
  },
  {
    path: "/invoices/create",
    name: "invoice-create",
    meta: { title: "Create Invoice", authRequired: true },
    component: () => import("../views/apps/invoices/create"),
  },
  {
    path: "/ui/alerts",
    name: "Alerts",
    meta: {
      title: "Alerts", authRequired: true,
    },
    component: () => import("../views/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "Buttons",
    meta: {
      title: "Buttons", authRequired: true,
    },
    component: () => import("../views/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "Cards",
    meta: { title: "Cards", authRequired: true },
    component: () => import("../views/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "Carousel",
    meta: { title: "Carousel", authRequired: true },
    component: () => import("../views/ui/carousel"),
  },
  {
    path: "/ui/dropdowns",
    name: "Dropdowns",
    meta: { title: "Dropdowns", authRequired: true },
    component: () => import("../views/ui/dropdowns"),
  },
  {
    path: "/ui/grid",
    name: "Grid",
    meta: { title: "Grid", authRequired: true },
    component: () => import("../views/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "Images",
    meta: { title: "Images", authRequired: true },
    component: () => import("../views/ui/images"),
  },
  {
    path: "/ui/modals",
    name: "Modals",
    meta: { title: "Modals", authRequired: true },
    component: () => import("../views/ui/modals"),
  },
  {
    path: "/ui/offcanvas",
    name: "offcanvas",
    meta: { title: "Offcanvas", authRequired: true },
    component: () => import("../views/ui/offcanvas"),
  },
  {
    path: "/ui/progress",
    name: "Progress",
    meta: { title: "Progress", authRequired: true },
    component: () => import("../views/ui/progress"),
  },
  {
    path: "/ui/placeholders",
    name: "Placeholders",
    meta: { title: "Placeholders", authRequired: true },
    component: () => import("../views/ui/placeholders"),
  },
  {
    path: "/ui/accordions",
    name: "accordions",
    meta: { title: "Accordions", authRequired: true },
    component: () => import("../views/ui/accordions"),
  },
  {
    path: "/ui/tabs",
    name: "tabs",
    meta: { title: "Tabs", authRequired: true },
    component: () => import("../views/ui/tabs"),
  },
  {
    path: "/ui/typography",
    name: "Typography",
    meta: { title: "Typography", authRequired: true },
    component: () => import("../views/ui/typography"),
  },
  {
    path: "/ui/embed-video",
    name: "embed-video",
    meta: { title: "Embed Video", authRequired: true },
    component: () => import("../views/ui/embed-video"),
  },
  {
    path: "/ui/ribbons",
    name: "ribbons",
    meta: { title: "Ribbons", authRequired: true },
    component: () => import("../views/ui/ribbons"),
  },
  {
    path: "/ui/lists",
    name: "lists",
    meta: { title: "Lists", authRequired: true },
    component: () => import("../views/ui/lists"),
  },
  {
    path: "/ui/utilities",
    name: "utilities",
    meta: { title: "Utilities", authRequired: true },
    component: () => import("../views/ui/utilities"),
  },
  {
    path: "/ui/notifications",
    name: "notifications",
    meta: { title: "Notifications", authRequired: true },
    component: () => import("../views/ui/notifications"),
  },
  {
    path: "/ui/general",
    name: "General",
    meta: { title: "General", authRequired: true },
    component: () => import("../views/ui/general"),
  },
  {
    path: "/ui/colors",
    name: "Colors",
    meta: { title: "Colors", authRequired: true },
    component: () => import("../views/ui/colors"),
  },
  {
    path: "/ui/badges",
    name: "badges",
    meta: { title: "Badges", authRequired: true },
    component: () => import("../views/ui/badges"),
  },
  {
    path: "/ui/media",
    name: "media",
    meta: { title: "Media", authRequired: true },
    component: () => import("../views/ui/media"),
  },
  {
    path: "/widgets",
    name: "widgets",
    meta: { title: "Widgets", authRequired: true },
    component: () => import("../views/widgets/index"),
  },
  {
    path: "/icons/boxicons",
    name: "boxicons-icon",
    meta: { title: "Boxicons", authRequired: true },
    component: () => import("../views/icons/boxicons"),
  },
  {
    path: "/icons/materialdesign",
    name: "Material Design",
    meta: { title: "material Design", authRequired: true },
    component: () => import("../views/icons/materialdesign"),
  },
  {
    path: "/icons/feather",
    name: "feather",
    meta: { title: "Feather", authRequired: true },
    component: () => import("../views/icons/feather"),
  },
  {
    path: "/icons/lineawesome",
    name: "lineawesome",
    meta: { title: "Line Awesome", authRequired: true },
    component: () => import("../views/icons/lineawesome"),
  },
  {
    path: "/icons/remix",
    name: "remix",
    meta: { title: "Remix", authRequired: true },
    component: () => import("../views/icons/remix"),
  },
  {
    path: "/icons/crypto",
    name: "crypto",
    meta: { title: "Crypto", authRequired: true },
    component: () => import("../views/icons/crypto"),
  },
  {
    path: "/tables/basic",
    name: "basic-table",
    meta: { title: "Basic Table", authRequired: true },
    component: () => import("../views/tables/basic"),
  },
  {
    path: "/tables/gridjs",
    name: "gridjs",
    meta: { title: "Grid JS", authRequired: true },
    component: () => import("../views/tables/grid/index"),
  },
  {
    path: "/form/advanced",
    name: "form-advanced",
    meta: { title: "Form Advance", authRequired: true },
    component: () => import("../views/forms/advanced"),
  },
  {
    path: "/form/elements",
    name: "form-elements",
    meta: { title: "Form Elements", authRequired: true },
    component: () => import("../views/forms/elements"),
  },
  {
    path: "/form/layouts",
    name: "Form Layouts",
    meta: { title: "Form Layouts", authRequired: true },
    component: () => import("../views/forms/layouts"),
  },
  {
    path: "/form/editors",
    name: "Form Editors",
    meta: { title: "Form Editors", authRequired: true },
    component: () => import("../views/forms/editors"),
  },
  {
    path: "/form/file-uploads",
    name: "File Uploads",
    meta: { title: "File Uploads", authRequired: true },
    component: () => import("../views/forms/file-uploads"),
  },
  {
    path: "/form/validation",
    name: "Form Validation",
    meta: { title: "Form Validation", authRequired: true },
    component: () => import("../views/forms/validation"),
  },
  {
    path: "/form/wizard",
    name: "Form Wizard",
    meta: { title: "Form Wizard", authRequired: true },
    component: () => import("../views/forms/wizard"),
  },
  {
    path: "/form/masks",
    name: "form-mask",
    meta: { title: "Form Mask", authRequired: true },
    component: () => import("../views/forms/masks"),
  },
  {
    path: "/form/pickers",
    name: "form-pickers",
    meta: { title: "Form Picker", authRequired: true },
    component: () => import("../views/forms/pickers"),
  },
  {
    path: "/form/range-sliders",
    name: "form-range-slider",
    meta: { title: "Form Range Slider", authRequired: true },
    component: () => import("../views/forms/range-sliders"),
  },
  {
    path: "/form/select",
    name: "form-select",
    meta: { title: "Form Select", authRequired: true },
    component: () => import("../views/forms/select"),
  },
  {
    path: "/form/checkboxs-radios",
    name: "form-checkboxs-radio",
    meta: { title: "Checkboxs & Radios", authRequired: true },
    component: () => import("../views/forms/checkboxs-radios"),
  },
  {
    path: "/landing",
    name: "landing",
    meta: { title: "Landing", authRequired: true },
    component: () => import("../views/landing/index"),
  },
  {
    path: "/nft-landing",
    name: "nft-landing",
    meta: { title: "Landing", authRequired: true },
    component: () => import("../views/landing/nft-landing"),
  },
  {
    path: "/pages/starter",
    name: "starter",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/starter"),
  },
  {
    path: "/pages/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../views/pages/profile/simple"),
  },
  {
    path: "/pages/profile-setting",
    name: "profile-setting",
    meta: { title: "Setting", authRequired: true },
    component: () => import("../views/pages/profile/setting"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/pages/timeline",
    name: "timeline",
    meta: { title: "Timeline", authRequired: true },
    component: () => import("../views/pages/timeline"),
  },
  {
    path: "/pages/faqs",
    name: "FAQs",
    meta: { title: "FAQs", authRequired: true },
    component: () => import("../views/pages/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "pricing",
    meta: { title: "Pricing", authRequired: true },
    component: () => import("../views/pages/pricing"),
  },
  {
    path: "/pages/team",
    name: "team",
    meta: { title: "Team", authRequired: true },
    component: () => import("../views/pages/team"),
  },
  {
    path: "/pages/search-results",
    name: "search-results",
    meta: { title: "Search Results", authRequired: true },
    component: () => import("../views/pages/search-results"),
  },
  {
    path: "/pages/sitemap",
    name: "sitemap",
    meta: { title: "Sitemap", authRequired: true },
    component: () => import("../views/pages/sitemap"),
  },
  {
    path: "/charts/chartjs",
    name: "chartjs-chart",
    meta: { title: "Chartjs", authRequired: true },
    component: () => import("../views/charts/chartjs/index"),
  },
  {
    path: "/charts/echart",
    name: "echart",
    meta: { title: "Echarts", authRequired: true },
    component: () => import("../views/charts/echart/index"),
  },
  {
    path: '/charts/apex-line',
    name: 'line',
    meta: {
      title: "Line Charts", authRequired: true,
    },
    component: () => import('../views/charts/apex/line/index')
  },
  {
    path: '/charts/apex-area',
    name: 'area-chart',
    meta: {
      title: "Area Charts", authRequired: true,
    },
    component: () => import('../views/charts/apex/area/index')
  },
  {
    path: '/charts/apex-bar',
    name: 'bar-chart',
    meta: {
      title: "Bar Charts", authRequired: true,
    },
    component: () => import('../views/charts/apex/bar/index')
  },
  {
    path: '/charts/apex-column',
    name: 'column-chart',
    meta: {
      title: "Column Charts", authRequired: true,
    },
    component: () => import('../views/charts/apex/column/index')
  },

  {
    path: '/charts/apex-mixed',
    name: 'mixed-chart',
    meta: {
      title: "Mixed Charts", authRequired: true,
    },
    component: () => import('../views/charts/apex/mixed/index')
  },
  {
    path: '/charts/apex-candlestick',
    name: 'candlestick',
    meta: {
      title: "Candlestick", authRequired: true,
    },
    component: () => import('../views/charts/apex/candlestick/index')
  },
  {
    path: '/charts/apex-boxplot',
    name: 'boxplot',
    meta: {
      title: "Boxplot", authRequired: true,
    },
    component: () => import('../views/charts/apex/boxplot/index')
  },
  {
    path: '/charts/apex-bubble',
    name: 'bubble',
    meta: {
      title: "Bubble Chart", authRequired: true,
    },
    component: () => import('../views/charts/apex/bubble/index')
  },
  {
    path: '/charts/apex-scatter',
    name: 'scatter',
    meta: {
      title: "Scatter", authRequired: true,
    },
    component: () => import('../views/charts/apex/scatter/index')
  },
  {
    path: '/charts/apex-heatmap',
    name: 'heatmap',
    meta: {
      title: "Heatmap", authRequired: true,
    },
    component: () => import('../views/charts/apex/heatmap/index')
  },
  {
    path: '/charts/apex-treemap',
    name: 'treemap',
    meta: {
      title: "Treemap", authRequired: true,
    },
    component: () => import('../views/charts/apex/treemap/index')
  },
  {
    path: '/charts/apex-pie',
    name: 'pie',
    meta: {
      title: "Apex Pie", authRequired: true,
    },
    component: () => import('../views/charts/apex/pie/index')
  },
  {
    path: '/charts/apex-radialbar',
    name: 'radialbar',
    meta: {
      title: "Apex Radialbar", authRequired: true,
    },
    component: () => import('../views/charts/apex/radiabar/index')
  },
  {
    path: '/charts/apex-radar',
    name: 'radar',
    meta: {
      title: "Apex Radar", authRequired: true,
    },
    component: () => import('../views/charts/apex/radar/index')
  },
  {
    path: '/charts/apex-polararea',
    name: 'polararea',
    meta: {
      title: "Polararea", authRequired: true,
    },
    component: () => import('../views/charts/apex/polararea/index')
  },
  {
    path: '/advance-ui/animation',
    name: 'animation',
    meta: {
      title: "Animation", authRequired: true,
    },
    component: () => import('../views/advance-ui/animation')
  },
  {
    path: '/advance-ui/highlight',
    name: 'highlight',
    meta: {
      title: "Highlight", authRequired: true,
    },
    component: () => import('../views/advance-ui/highlight')
  },
  {
    path: '/advance-ui/scrollbar',
    name: 'scrollbar',
    meta: {
      title: "Scrollbar", authRequired: true,
    },
    component: () => import('../views/advance-ui/scrollbar')
  },
  {
    path: '/advance-ui/scrollspy',
    name: 'scrollspy',
    meta: {
      title: "Scrollspy", authRequired: true,
    },
    component: () => import('../views/advance-ui/scrollspy')
  },
  {
    path: '/advance-ui/sweetalerts',
    name: 'sweetalerts',
    meta: {
      title: "Sweet Alerts", authRequired: true,
    },
    component: () => import('../views/advance-ui/sweetalerts')
  },
  {
    path: '/advance-ui/swiper',
    name: 'Swiper Slider',
    meta: {
      title: "Swiper", authRequired: true,
    },
    component: () => import('../views/advance-ui/swiper')
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Oflone", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: {
      title: "Google Maps", authRequired: false,
    },
    component: () => import("../views/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { title: "Leaflet Maps", authRequired: true },
    component: () => import("../views/maps/leaflet/index"),
  },
];

// Funzioni
const sendGetRequest = async () => {
  try {
   
    if (localStorage.getItem('tk')) {
    let tk = localStorage.getItem('tk')
    const resp = await axios.get(`${serverConfig.EP}auth/check-access/`, {
          headers: {
              'authorization': `Bearer ${tk}`
          }
      });
     
      if (resp.data.authorization==true){
       return true
      } else {
        localStorage.removeItem('tk')
        return false
      }

    } else {
      return false
    }


  } catch (err) {
      localStorage.removeItem('tk')
      console.log(err.message);
      return false
      
  }
};