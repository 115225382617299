import { createStore } from 'vuex'
import createPersistedState from "vuex-plugin-persistedstate";

import modules from './modules'

const store = createStore({
  modules,
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production',
})

export default store

