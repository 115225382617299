import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
const { getStorage } = require('firebase/storage');

const firebaseConfig = {
  apiKey: "AIzaSyBF1wXkNz_d_EQouSrkwBX_1EL3b6Gz3Ps",
  authDomain: "sunerp-2.firebaseapp.com",
  projectId: "sunerp-2",
  storageBucket: "sunerp-2.appspot.com",
  messagingSenderId: "791977926396",
  appId: "1:791977926396:web:befbe40ecd049f6a1d0179",
  measurementId: "G-FV4VN7M594"
}
/*
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
}
*/
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const bucket = getStorage

export { auth,bucket,app }
/*
VUE_APP_APIKEY= "AIzaSyBF1wXkNz_d_EQouSrkwBX_1EL3b6Gz3Ps"
VUE_APP_AUTHDOMAIN="sunerp-2.firebaseapp.com"
VUE_APP_DATABASEURL="sunerp-2.firebaseapp.com"
VUE_APP_PROJECTId= "sunerp-2"
VUE_APP_STORAGEBUCKET="sunerp-2.appspot.com"
VUE_APP_MESSAGINGSENDERID="791977926396"
VUE_APP_APPId="1:791977926396:web:befbe40ecd049f6a1d0179"
VUE_APP_MEASUREMENTID="G-FV4VN7M594"
*/