const serverConfig = {
	//'https://api.sunerp.it/v1/',
	EP : process.env.VUE_APP_EP_API,
	EP_SERVICE : process.env.VUE_APP_EP_API_SERVICE,
	IMG_HEIGHT:process.env.VUE_APP_IMG_HEIGHT,
	GOOGLE_API_KEY:process.env.VUE_APP_GOOGLE_API_KEY,
	//PAYPAL_CLIENT_ID: process.env.PAYPAL_CLIENT_ID,
	PAYPAL_CLIENT_ID :'Ab89RG0E-qDJVW_L3YecVBE6n502QAMpcWVm8bR897dMSQsImFI8-sXL-hvms8uiOG3Ss80TqTDAndQL',
	testENV:process.env.VUE_APP_TESTENV,
	APP_NAME:process.env.VUE_APP_NAME,
}
module.exports = serverConfig