import { createWebHistory, createRouter } from "vue-router";
//import axios from 'axios';
import routes from './routes'
import appConfig from "../../app.config";
//import epConfig from "@/helpers/ep-backend"
 //import store from "@/state/store.js"
 //import { useCookies } from "vue3-cookies";
 //const { cookies } = useCookies();
//import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css';
 //import { auth } from '@/firebase'

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

// Before each route evaluates...

//router.beforeEach((to, from, next) => {
  //router.beforeEach( async (routeTo, routeFrom, next) => {
    // Check if auth is required on this route
    // (including nested routes).
    //const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
     //const currentLogin = await  auth.currentUser;

    // If auth isn't required for the route, just continue.
     //if (!authRequired) return next();

     //if ( store.getters['auth/loggedIn']==true && currentLogin==null){
       //localStorage.removeItem('VUEX-PERSISTEDSTATE')
       //cookies.remove('tk')
       //location.reload()
    // }
   
    // If auth is required and the user is logged in...
     //if (store.getters['auth/loggedIn']) {
      // Validate the local user token...
      /*
      return store.dispatch('auth/validate').then((validUser) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser ? next() : redirectToLogin();
      });
      */
     
     // return next()
     //} else {
    //   redirectToLogin();
   //  }

    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
   //  redirectToLogin();

    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-inner-declarations
    // function redirectToLogin() {
      // Pass the original route to the login component
     //  next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    // }
 

 //})


router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next()
})

export default router
