import { getFirebaseBackend } from '../../authUtils.js'
import router from '@/router'
import axios from 'axios';
import Swal from "sweetalert2";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import serverConfig from '@/helpers/config.js'

// Add the Firebase products that you want to use
import "firebase/compat/auth";
//import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/compat/auth';
//import { auth } from 'firebase/compat/auth'
import { auth } from '@/firebase'



import { createUserWithEmailAndPassword,  } from 'firebase/auth';
//import { refresh } from 'aos';

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
    userSalesRoute:[],
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
    set_token(state, newValue){
        state.token = newValue
    },
    SET_PAGES_SETTING(state, newValue){
      state.pages_setting = newValue
  },
  SET_USER_PRICELIST(state, newValue) {
    state.userPricelist = newValue
  },
  SET_USER_SALESROUTE(state, newValue) {
    state.userSalesRoute = newValue
  }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    getToken(state) {
        return state.token
    },
    getPage(state) {
      return state.pages_setting
    },
    getSR(state) {
      return state.userSalesRoute
    }

}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    /*
    init({ state, dispatch }) {
        dispatch('validate')
            const user = response
                   

            commit('SET_CURRENT_USER', user)
            return user
    },
    */
    // Logs in the current user.
    async refreshToken({ commit}) {

      const token =  cookies.get("tk_refresh")
     
      
      commit('set_token', token)
    },
 //localStorage.setItem('nlkoctopus_eco_tk23', JSON.stringify(token));
    clearSessionStorage(){
     
      clearSession()
     
    },
    async getProfile({ commit}) {
      let tk = localStorage.getItem('tkk')
      let userid =  localStorage.getItem('userId')
      axios.get(`${serverConfig.EP}auth/profile/${userid}`,  
          {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
          
          sessionStorage.setItem('profile',response.data)
          localStorage.setItem('profile',response.data)
          commit('set_token', response.data)
         
          
        })
    },
    async getSalesRoute({ commit}, id ) {
      let tk = localStorage.getItem('tk')
     
      axios.get(`${serverConfig.EP}sales-route/srlcompany/${id}`,  
          {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
          commit('SET_USER_SALESROUTE',  response.data)
          
        })    
    },

    async getUsers({ commit}) {
      let tk = localStorage.getItem('tkk')
      axios.get(`${serverConfig.EP}auth/users`,  
          {headers: { authorization:`Bearer ${tk}`}} 
        ).then((response)=>{
          commit('set_token', response.data)    
        })
    },
   // async logIn({ commit, dispatch, getters }, { email, password } = {}) {
    async logIn({ commit}, { email, password } = {}) {
      try {

        axios.interceptors.response.use(
          (response) => {
              
              return response;
          },
          (error) => {

              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } 
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }
      
          }
        );


        axios.post(`${serverConfig.EP}auth/login`,  
          { 
              "email": email,
              "password": password,
          },
          {
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                html: "Progresso operazione <b></b>.",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            onDownloadProgress: (event) =>
            {
              const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
            
              let timerInterval;
              Swal.fire({
                title: "Download data",
                html: "Progresso operazione  <strong></strong>.",
                timer: percentDownloaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                 // const timer = Swal.getPopup().querySelector("b");
                 /* 
                 timerInterval = setInterval(() => {
                    //timer.textContent = `${Swal.getTimerLeft()}`;
                    //Swal.getHtmlContainer().querySelector('strong').textContent = Swal.getTimerLeft().toString();

                  }, 100);
                  */
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then(() => {
                
              });

            }
          }
   
       ).then((response)=>{
        
        sessionStorage.setItem('tk',response.data.accessToken)
        localStorage.setItem('tk',response.data.accessToken)
        
        sessionStorage.setItem('userId',response.data.userId)
        localStorage.setItem('userId',response.data.userId)

        commit('SET_CURRENT_USER',  response.data.userData)
        commit('set_token',response.data.accessToken)
        cookies.set('tk',response.data.accessToken)

          Swal.fire({
            title:"Bentornati",
            text:"Bentornati sulla nostra applicazione",
            icon:"success",
            showCancelButton:false,
            showConfirmButton:false,
            timer:2000,
          }).then(()=>{
            router.push('/')  
          })
        
       })

      }  catch (error) {
      
        switch(error.code) {
         
          case 'auth/user-not-found':
            Swal.fire({
              title:"Utente non trovato",
              text:"Spiacente, utente non trovato.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2000,
            })
            break
          case 'auth/wrong-password':
            Swal.fire({
              title:"Password non valida",
              text:"Spiacente, la password inserita non è valida.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2000,
            })
            break
            case 'auth/invalid-email':
              Swal.fire({
                title:"Inserisci credenziali",
                text:"Inserisci delle credenziali valide, per favore",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              break

          default:
            Swal.fire({
              title:"Ops, qualcosa è andato storto",
              text:"Spiacente, qualcosa è andato storto. Riprova.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2000,
            })
            break;
        }

        return
      }

    

    },

    fetchUser ({ commit }) {
      auth.onAuthStateChanged(async user => {
        if (user === null) {
          commit('SET_CURRENT_USER',null)
          commit('set_token',null)

        } else {
          commit('SET_CURRENT_USER', user)
          commit('set_token',user.accessToken)
          cookies.set('tk',user.accessToken)
        /*
          if (router.isReady() && router.currentRoute.value.path === '/account/login') {
            router.push('/')
          }
        */


        }
      })
    },
    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        localStorage.removeItem('VUEX-PERSISTEDSTATE')
        commit('SET_CURRENT_USER', null)
        commit('set_token', null);
        if (cookies.get("tk")){
            cookies.remove("tk")
        }
        

        
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then((response) => {
                resolve(true);
                location.reload()

              }).catch((error) => {
                reject(this._handleError(error));
            })
        });
      
    },

    // register the user
   // async register({ commit, dispatch, getters }, { username, email, password } = {}) {
 
    async register({ commit }, { username, email, password } = {}) {
      if (username && email && password){
         try {
            await createUserWithEmailAndPassword(auth, email, password)

            
          } catch (error) {
            switch(error.code) {
              case 'auth/email-already-in-use':
                 
                alert('Email già in uso!')
                break
              case 'auth/invalid-email':
                alert("Invalid email")
                break
              case 'auth/operation-not-allowed':
                alert("Operation not allowed")
                break
              case 'auth/weak-password':
                alert("Weak password")
                break
              default:
                alert("Something went wrong")
            }

            return
          }

          commit('SET_CURRENT_USER', auth.currentUser)
          if (auth.currentUser){
            auth.currentUser.getIdToken(true).then(token => {
              commit('set_token', token);

               router.push('/')  
            });
          }
      }    

      
      //router.push('/')

        //if (getters.loggedIn) return dispatch('validate')
        
        /*
        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
        */
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state))
}
function clearSession(){
  localStorage.removeItem('VUEX-PERSISTEDSTATE')
  sessionStorage.removeItem("auth.currenUser");
}
