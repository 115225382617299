
//import router from '@/router'
import axios from 'axios';
import Swal from "sweetalert2";
//import { useCookies } from "vue3-cookies";
//const { cookies } = useCookies();

import serverConfig from '@/helpers/config.js'


export const mutations = {

    SET_SETTINGS_ROLE(state, value){
        state.settingRole = value
    },
    SET_SETTINGS_AREA(state, value){
        state.settingArea = value
    },
    SET_SETTINGS_PARAMS(state, value) {
        state.settingParams = value
    },
    SET_SETTINGS_TAG(state, value) {
        state.settingTag = value
    },
}

export const getters = {
  
    getSettingsRole(state) {
        return state.settingRole
    },
    getSettingsArea(state) {
        return state.settingArea
    },
    getSettingsParams(state) {
        return state.settingParams
    },
    getByTag(state){
        return state.settingTag
    }
}

export const actions = {

    async getSettings_userolearea({commit}) {

        if (localStorage.getItem('tk')){
            try {
                axios.get(`${serverConfig.EP}settings/`,  
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
                ).then((response)=>{

                    let roles = []
                    let areas = []
                    for  (let item of response.data) {
                        if (item.tag == 'app-label-role'){
                            roles.push({
                                value:item.value,
                                label:item.name,
                            })
                        }
                        if (item.tag == 'app-label-area'){
                            areas.push({
                                value:item.value,
                                label:item.name,
                            })
                        }
                    }

                    
                    commit('SET_SETTINGS_ROLE', roles)
                    commit('SET_SETTINGS_AREA', areas)
                })
                .catch(error=>{
                    if (error =='Error: Request failed with status code 500'){
                        Swal.fire({
                          title:"Errore 500",
                          text:"Spiacente, il server ha riscontrato un errore",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                      }
                })

            } catch(e) {
                
                Swal.fire({
                    title:"Errore 500",
                    text:"Spiacente, il server ha riscontrato un errore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                })

            }
        }

    },
    async getSettings_Params({commit}) {

        if (localStorage.getItem('tk')){
            try {
                axios.get(`${serverConfig.EP}settings/all/params/list`,  
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
                ).then((response)=>{
                    
                    commit('SET_SETTINGS_PARAMS', response.data)
                })
                .catch(error=>{
                    if (error =='Error: Request failed with status code 500'){
                        Swal.fire({
                          title:"Errore 500",
                          text:"Spiacente, il server ha riscontrato un errore",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                      }
                })

            } catch(e) {
                
                Swal.fire({
                    title:"Errore 500",
                    text:"Spiacente, il server ha riscontrato un errore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                })

            }
        }

    },
    async getBytag({commit}, tag) {

        if (localStorage.getItem('tk')){
            try {
                axios.get(`${serverConfig.EP}settings/all/documents/tag/${tag}`,  
                {headers: { authorization:`Bearer ${localStorage.getItem('tk')}`}} 
                ).then((response)=>{
                    
                    commit('SET_SETTINGS_TAG', response.data)
                })


            } catch(e) {
                
                Swal.fire({
                    title:"Errore 500",
                    text:"Spiacente, il server ha riscontrato un errore",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                })

            }
        }

    },
}

// ===
// Private helpers
// ===

