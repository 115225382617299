import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import vueCountryRegionSelect from 'vue3-country-region-select'
import GoogleMap from 'vue-google-maps-ui'

import { quillEditor } from 'vue3-quill'


import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
/*
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazInputNumber from 'maz-ui/components/MazInputNumber'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import 'maz-ui/css/main.css'
*/
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
import Vue3Storage from "vue3-storage-secure";

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import { Html2CanvasPlugin } from 'vue3-html2canvas';
import VueExcelEditor from 'vue3-excel-editor'
import VuePictureSwipe from 'vue3-picture-swipe';
import Camera from "simple-vue-camera";
import './registerServiceWorker'
//import VueEasyLightbox from 'vue-easy-lightbox'
import ganttastic from '@infectoone/vue-ganttastic'

import dayjs from 'dayjs'

import vue3ExcelJson from 'vue3-excel-json';
import 'vue3-excel-json/dist/style.css';


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

createApp(App)
    .provide('dayJS', dayjs) 
    .use(quillEditor)
    .use(store)
    .use(router)
    .use(Vue3Storage, { namespace: "jarvis_", secureKey: "246810" })
    .use(Html2CanvasPlugin)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .use(VueExcelEditor)
    .use(vue3ExcelJson)
 
    .use(dayjs)
    .component("camera", Camera)
    .component(VueFeather.type, VueFeather)
    .component('vue-picture-swipe', VuePictureSwipe)
    .component('Googlemap', GoogleMap)
    .use(ganttastic)
    /*
    .component('MazBtn', MazBtn)
    .component('MazInput', MazInput)
    .component('MazInputNumber',MazInputNumber)
    .component('MazPhoneNumberInput', MazPhoneNumberInput)
    */
    .use(Maska)
    .use(Particles)
    .use(vueCountryRegionSelect)
    .use(i18n)
    .use(vClickOutside).mount('#app')